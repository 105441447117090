import {environment} from "@env"
import {Injectable} from '@angular/core'
import {logger} from "nx/src/utils/logger"
import {HttpClient} from "@angular/common/http"
import {catchError, map, Observable, of} from "rxjs"
import {
  AddressModel,
  AddressRouteModel,
} from "@domain/address"
// import {GeoDirectionsResult} from "../../../../domain/src/lib/map.model"
import { ContactAddressModel } from '@domain/contact'

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(
      private http: HttpClient,
  ) {
  }

  /*
  getAddressesContacts(): Observable<AddressContactModel[]> {
    const url = `${environment.apiUrl}/addresses-contacts`
    return this.http.get<AddressContactModel[]>(url).pipe(
        map(values => {
          return values
        }),
    )
  }
   */

  getAddresses(): Observable<AddressModel[]> {
    const url = `${environment.apiUrl}/addresses`
    return this.http.get<AddressModel[]>(url).pipe(
        map(values => {
          return values
        }),
    )
  }

  getContactAddresses(contactId: number): Observable<ContactAddressModel[]> {
    const url = `${environment.apiUrl}/addresses/contacts/${contactId}`
    return this.http.get<ContactAddressModel[]>(url).pipe(
        map(values => {
          return values
        }),
    )
  }

  addAddress(address: AddressModel): Observable<any> {
    const url = `${environment.apiUrl}/addresses`
    // const res = this.http.post(url, address).pipe(
    return this.http.post<number>(url, address).pipe(
        map(value => {
          return value
        }),
        // catchError(error => {
        //   logger.error(`Error: $error`)
        //   return of(null)
        // }),
    )
    // logger.debug('add address: res=', res)
    // return res
    /*
     return this.http.post<AddressModel>(url, address).pipe(
     catchError(this.handleError('addAddress', address)),
     )
     */
  }

  updateAddress(address: AddressModel): Observable<number> {
    const url = `${environment.apiUrl}/addresses`
    // return this.http.put<boolean>(url, address).pipe(
    const res = this.http.put<number>(url, address).pipe(
        map(value => {
          return value
        }),
    )
    logger.debug('address-service#updateAddress: res=', res)
    return res
    /*
     return this.http.put<boolean>(url, address).pipe(
     catchError(this.handleError('updateAddress', address)),
     )
     */
  }

  deleteAddressesByIds(ids: number[]): Observable<any> {
    const url = `${environment.apiUrl}/addresses/${ids}`
    logger.debug('address-service#deleteAddressesByIds: ids=', ids, 'url=', url)
    return this.http
               .delete(url)
               .pipe(
                   catchError(this.handleError('deleteAddress')),
               )
  }

  addRoute(addressRoute: AddressRouteModel): Observable<any> {
    const url = `${environment.apiUrl}/address-routes`
    logger.debug('address-service#addRoute: addressRoute=', addressRoute)
    return this.http.post<number>(url, addressRoute).pipe(
        map(value => {
          return value
        }),
    )
  }

  /*
  // addAddressToCustomer(customerId: number, addressId: number, addressTypedId: number, ordering: number): Observable<any> {
  addContactAddress(contactAddress: ContactAddressModel): Observable<any> {
    const url = `${environment.apiUrl}/contacts/addresses`
    logger.debug('address-service#addAddressToCustomer: addressContact=', contactAddress)
    return this.http.post<number>(url, contactAddress).pipe(
        map(value => {
          return value
        }),
    )
  }
   */

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<boolean> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error) // log to console instead
      // TODO: better job of transforming error for address consumption
      logger.error(`address-service#${operation} failed: ${error.message}`)
      // Let the app keep running by returning an empty result.
      return of(false)
    }
  }

}
